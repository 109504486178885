<!--
 * @Descripttion: 
 * @Author: 张涛
 * @Date: 2022-11-07 15:51:22
 * @LastEditors: 张涛
 * @LastEditTime: 2023-01-12 10:05:09
-->
<template>
  <div class="home">
    <Navs />
    <Banner></Banner>
    <Stage></Stage>
    <NewView></NewView>
    <!--实力-->
    <!-- <Power></Power> -->
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import Navs from '@/components/navs.vue'
import Banner from '@/components/home/Banner.vue'  //banner
import Stage from '@/components/home/Stage.vue'  //业务领域
import NewView from '@/components/home/NewView.vue'  //业务领域
import Power from '@/components/home/power.vue'
import Footer from '@/components/Footer.vue'
export default {
  name: 'IndexView',
  components: {
    Navs,
    Banner,
    Stage,
    NewView,
    Footer,
    Power
  }
}
</script>
