<!--
 * @Descripttion: 
 * @Author: 张涛
 * @Date: 2022-11-07 16:48:42
 * @LastEditors: 张涛
 * @LastEditTime: 2023-01-12 10:06:50
-->
<template>
  <div class="stage">
    <div class="stage_des">
      <div class="stage_box">
        <div class="stage_title" data-aos="fade-up" data-aos-delay="100">业务领域</div>
        <div class="stage_des_text" data-aos="fade-up" data-aos-delay="150">
          先锋绿能已在油气行业投资了油气田生产用电储能设备，系国内油气田行业第一例大功率注水泵的储能系统应用案例，现已投入运行并服务于客户，下一步拟在油气田勘探开发和生产场景进一步推广储能技术的应用。
        </div>
        <div class="stage_swiper">
          <div class="swiper-container swiper2">
            <div class="swiper-wrapper">
              <div class="swiper-slide">

                <div class="gird_top" :style="'background: url('+bg1+')'"></div>
                <div class="gird_bot">
                  <div class="gird_cont" data-aos="fade-left" data-aos-delay="100">
                    <div class="gird_title">清洁替代与供电服务</div>
                    <div class="gird_english">Cleaning substitution and power supply service</div>
                    <div class="gird_line"></div>
                    <div class="gird_text">以现代信息技术为基础、围绕“源网荷储”一体化解决方案，为客户提供从规划设计、投资、建设、运营、大修技改等一体化、全生命周期的供能服务。降低客户能源投资和用能成本，让客户安心用能、清洁用能，专注于主营业务。</div>
                    <div class="gird_more" @click="handleMore('eleSupply')">了解更多</div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="gird_top" :style="'background: url('+bg2+')'"></div>
                <div class="gird_bot">
                  <div class="gird_cont" data-aos="fade-left" data-aos-delay="100">
                    <div class="gird_title">电力技术服务</div>
                    <div class="gird_english">Power technology service

                    </div>
                    <div class="gird_line"></div>
                    <div class="gird_text">为客户提供电力设施、新能源场站电力设施运行维
                      护管理，并根据客户生产和用能需求，提供电力设
                      施承装、修、试服务，电力设备技术改造和设备租
                      赁服务。</div>
                    <div class="gird_more" @click="handleMore('eleTechnology')">了解更多</div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="gird_top" :style="'background: url('+bg3+')'"></div>
                <div class="gird_bot">
                  <div class="gird_cont" data-aos="fade-left" data-aos-delay="100">
                    <div class="gird_title">综合能源服务</div>
                    <div class="gird_english">Comprehensive energy
                    </div>
                    <div class="gird_line"></div>
                    <div class="gird_text">为客户提供能源增值服务，包括但不限于：用户多种能源优化组合方案，提供发电、供热、供冷、供气、供水等智能化多种能源类型的综合能源服务；提供能源工程服务、投资服务和运营服务等多种类型的综合能源服务。</div>
                    <div class="gird_more" @click="handleMore('integralEnergy')">了解更多</div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="gird_top" :style="'background: url('+bg4+')'"></div>
                <div class="gird_bot">
                  <div class="gird_cont">
                    <div class="gird_title">新能源</div>
                    <div class="gird_english">New energy
                    </div>
                    <div class="gird_line"></div>
                    <div class="gird_text">公司主要致力于光伏发电（集中式、分布式）、风力发电项目的投资、建设和运营</div>
                    <div class="gird_more" @click="handleMore('newEnergy')">了解更多</div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="gird_top" :style="'background: url('+bg5+')'"></div>
                <div class="gird_bot">
                  <div class="gird_cont">
                    <div class="gird_title">能源信息化及大数据服务</div>
                    <div class="gird_english">Energy informatization and big data service
                    </div>
                    <div class="gird_line"></div>
                    <div class="gird_text">利用物联网、４G、大数据、安全技术构建“设备定制、智能监测、数据赋能、数字运营“ 的能源数字化服务体系。</div>
                    <div class="gird_more" @click="handleMore('bigData')">了解更多</div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="gird_top" :style="'background: url('+bg6+')'"></div>
                <div class="gird_bot">
                  <div class="gird_cont">
                    <div class="gird_title">能源交易</div>
                    <div class="gird_english">Energy trading
                    </div>
                    <div class="gird_line"></div>
                    <div class="gird_text">先锋绿能基于用能绿色、绿色能源生产、运营和综合能源服务，聚焦电-碳一体化托管交易服务，以清洁能源替代核心，成为领先的能源交易生态托管服务商。</div>
                    <div class="gird_more" @click="handleMore('energyTrading')">了解更多</div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 如果需要分页器 -->
            <!-- <div class="swiper-pagination"></div> -->
            <!-- 如果需要导航按钮 -->

          </div>
          <div class="swiper-button-prev prev_btn"></div>
          <div class="swiper-button-next next_btn"></div>
        </div>
      </div>
    </div>
    <div class="stage_bot"></div>

  </div>

</template> 

<script>
import Swiper from 'swiper'; // 注意引入的是Swiper
import 'swiper/css/swiper.min.css' // 注意这里的引入

// // import bg2 from 'https://cdn.minexiot.com/xfweb/images/2.jpg'
// import bg3 from '@/assets/images/bg3.jpg'
// import bg4 from '@/assets/images/xny.jpg'
// import bg5 from '@/assets/images/sz.jpg'
// import bg6 from '@/assets/images/nyjy.png'
export default {
  name: 'StageView',
  data() {
    return {
      // swiper实例  实例上有各种方法
      swiperInstance2: null,
      bg1: 'https://cdn.minexiot.com/xfweb/images/business/%E6%B2%B9%E6%B0%94%E7%94%B0%E5%8B%98%E6%8E%A2%E5%BC%80%E5%8F%91%E4%BE%9B%E7%94%B5%E6%9C%8D%E5%8A%A1.jpg',
      bg2: 'https://cdn.minexiot.com/xfweb/images/business/%E7%94%B5%E5%8A%9B%E8%BF%90%E8%A1%8C%E7%BB%B4%E6%8A%A4%E5%A7%94%E6%89%98%E7%AE%A1%E7%90%86.jpg',
      bg3: 'https://cdn.minexiot.com/xfweb/images/business/%E7%87%83%E6%B0%94%E5%88%86%E5%B8%83%E5%BC%8F%E8%83%BD%E6%BA%90%EF%BC%88%E4%B8%89%E8%81%94%E4%BE%9B%EF%BC%89.jpg',
      bg4: 'https://cdn.minexiot.com/xfweb/images/business/%E9%9B%86%E4%B8%AD%E5%BC%8F%E5%85%89%E4%BC%8F.jpg',
      bg5: 'https://cdn.minexiot.com/xfweb/images/business/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20221123170951.jpg',
      bg6: 'https://cdn.minexiot.com/xfweb/images/business/%E7%94%B5%E5%8A%9B%E4%BA%A4%E6%98%93.jpg',
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.swiperInstance2 = new Swiper('.swiper2', {
        loop: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,//用户操作swiper之后，是否禁止autoplay。默认为true：停止。
        },
        loopedSlides: 6,//在loop模式下使用slidesPerview:'auto',还需使用该参数设置所要用到的loop个数。
        slidesPerView: 3,
        // spaceBetween: 1,
        roundLengths: true,
        navigation: {
          nextEl: ".next_btn",
          prevEl: ".prev_btn",
        },
      })
    })



  },
  methods: {
    handleMore(type) { //跳转到业务领域页面
      this.$router.push({ path: '/business#' + type })
    }
  }
}
</script>
<style scoped lang="scss">
.stage {
  width: 100%;

  position: relative;
}

.stage_des {
  padding-top: 100px;
  background: url("https://cdn.minexiot.com/xfweb/images/ywbg.png");
  background-position: center;
  background-size: cover;
  height: 580px;
  box-sizing: border-box;

  .stage_box {
    width: 1140px;
    margin: auto;
  }
  .stage_title {
    padding-left: 30px;
    font-size: 36px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: bold;
    color: #ffffff;
    line-height: 36px;
  }
}
.stage_des_text {
  width: 1077px;
  margin-top: 30px;
  padding-left: 30px;
  font-size: 16px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  color: #ffffff;
  line-height: 28px;
}
.stage_bot {
  height: 400px;
  width: 100%;
  background: #f5f5f5;
}

.stage_swiper {
  position: relative;
  width: 1140px;
  height: 620px;
  margin: 58px auto 0 auto;
}
.prev_btn {
  position: absolute;
  top: 344px;
  left: -60px;
}
.next_btn {
  position: absolute;
  top: 344px;
  right: -60px;
}
.swiper2 {
  width: 100%;
  height: 100%;
}
.swiper-slide {
  height: 640px;
  // width: 380px;
  // border-right: 1px solid #f5f5f5;
  background-size: cover;
  background-position: center;
  box-sizing: border-box;
}

.gird_top {
  height: 300px;
  width: 100%;

  background-position: center !important;
  background-size: cover !important;
}
.gird_bot {
  height: 320px;
  background: #fff;
  position: relative;
}
.gird_cont {
  position: absolute;
  height: 320px;
  z-index: 99;
  padding: 37px 0 0 30px;
  top: 0px;
  bottom: 0px;
  width: 100%;

  transition: all 0.3s ease-in-out;
  .gird_title {
    font-size: 20px;

    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    color: #209701;
    line-height: 27px;
  }
  .gird_english {
    margin-top: 20px;
    font-size: 14px;
    min-height: 28px;
    font-family: Roboto-Regular, Roboto;
    color: #209701;
    line-height: 14px;
  }
  .gird_line {
    margin-top: 20px;
    width: 40px;
    height: 2px;
    background: #209701;
  }
  .gird_text {
    width: 312px;
    height: 72px;
    margin-top: 20px;
    font-size: 14px;

    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    color: #333333;
    line-height: 24px;
    text-align: justify;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }
  .gird_more {
    position: relative;
    cursor: pointer;
    margin-top: 30px;
    width: 150px;
    height: 32px;
    padding-left: 30px;
    border-radius: 16px;
    border: 1px solid #999999;
    color: #333333;
    font-size: 14px;
    line-height: 31px;
  }
  .gird_more::after {
    content: url("@/assets/images/rightIcon.png");
    position: absolute;
    right: 21px;
    top: 2px;
    width: 13px;
    height: 13px;
  }
}
.swiper-slide:hover .gird_bot .gird_cont {
  top: -40px;
  height: 360px;
  background: linear-gradient(180deg, #3bb201 0%, #1c8800 100%);
}
.swiper-slide:hover {
  .gird_more {
    border: 1px solid #ffffff;
    color: #fff;
  }
  .gird_title,
  .gird_english,
  .gird_text {
    color: #fff;
  }
  .gird_more::after {
    content: url("@/assets/images/rightIcon1.png");
  }
  .gird_line {
    background: #fff;
  }
}

.swiper-button-prev,
.swiper-button-next {
  border: none;
  outline: none;
  height: 40px;
  width: 40px;
}
.swiper-button-prev:after,
.swiper-button-next:after {
  content: "";
}
.swiper-button-prev {
  background: url("@/assets/images/next.png");
  background-size: cover;
  background-position: center;
  transform: rotate(180deg);
}
.swiper-button-next {
  background: url("@/assets/images/next.png");
  background-size: cover;
  background-position: center;
}
.swiper-button-prev:hover {
  background: url("@/assets/images/prev.png");
  background-size: cover;
  background-position: center;
  transform: rotate(0deg);
}
.swiper-button-next:hover {
  background: url("@/assets/images/prev.png");
  background-size: cover;
  background-position: center;
  transform: rotate(180deg);
}
// .swiper-button-prev:after {
//   height: 40px;
//   width: 40px;
//   content: url("@/assets/images/next.png") !important;
//   transform: rotate(180deg);
// }
// .swiper-button-next:after {
//   height: 40px;
//   width: 40px;
//   content: url("@/assets/images/next.png") !important;
// }
// .swiper-button-prev:hover .swiper-button-prev:after {
//   content: url("@/assets/images/prev.png") !important;
// }
</style>
