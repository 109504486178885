<!--
 * @Descripttion: 
 * @Author: 张涛
 * @Date: 2022-11-07 14:17:51
 * @LastEditors: 张涛
 * @LastEditTime: 2022-11-28 17:49:59
-->
<template>
  <div ref="app" id="app" style="display:none">
    <!-- <Navs ref="child"></Navs> -->
    <router-view class="content" :key="key" />
  </div>
</template>
<script>
  import Navs from '@/components/navs.vue'
  import Footer from '@/components/Footer.vue'
  export default {
    name: 'app',
    components: {
      Navs,
      Footer
    },
    data() {
      return {

        scroll: 0, //滚动的距离
        direction: 'down' //默认方向往下
      }
    },
    mounted() {
      //解决预渲染页面刷新会先加载首页index内容出现闪烁的问题
      this.$refs.app.style.display = "block";
      window.addEventListener('scroll', this.handleScroll(() => {
        var scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
        if (this.scroll > scrollTop) { //向上滚动
          this.direction = 'up';
        } else {
          this.direction = 'down'
        }
        this.scroll = scrollTop;
        if (this.direction == 'up' && scrollTop > 70) { //若网上滚动，菜单栏悬浮到顶部
          document.querySelector('.navs').style.transform = 'translateY(-0px)'
          document.querySelector('.navs').classList.add('header-fixed')
        } else { //若网上滚动，菜单栏不进行悬浮处理，到一定
          if (scrollTop <= 70) {
            document.querySelector('.navs').classList.remove('header-fixed')
          }
          document.querySelector('.navs').style.transform = 'translateY(-' + scrollTop + 'px)'
        }

      }, 100), true)
    },
    methods: {
      handleScroll(fn, delay) {
        let tirm = null;
        return function () {
          if (tirm !== null) {
            clearTimeout(tirm)
          } 
          tirm = setTimeout(() => {
            fn.call(this);
          }, delay)
        }

      }
    },
    computed: {
      key() {
        return this.$route.name !== undefined ? this.$route.name + +new Date() : this.$route + +new Date()
      }
    },
    beforeDestroy() {
      document.removeEventListener("scroll", this.handleScroll);
    },
  }
</script>

<style lang="scss">
  @import "./assets/font/font.css";

  * {
    padding: 0;
    margin: 0;
  }

  @media screen and (min-width: 1920px) {
    html {
      font-size: 16px !important;
    }
  }

  html,
  body {
    position: relative;
    // height: 100%;
    /* 隐藏滚动条 */
    scrollbar-width: none;
    /* firefox */
    -ms-overflow-style: none;
    /* IE 10+ */
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  }

  #app {}

  .content {
    min-height: 500px;
  }

  nav {
    padding: 30px;

    a {
      font-weight: bold;
      color: #2c3e50;

      &.router-link-exact-active {
        color: #42b983;
      }
    }
  }

  ::-webkit-scrollbar {
    display: none;
    /* Chrome Safari */
  }
</style>