<!--
 * @Descripttion: 
 * @Author: 张涛
 * @Date: 2022-11-07 16:48:42
 * @LastEditors: 张涛
 * @LastEditTime: 2022-11-18 10:30:27
-->
<template>
  <div class="newview">
    <div class="new_box">
      <div class="new_head justify-between">
        <span class="new_head_text">新闻动态</span>
        <span class="new_more" @click="handleMore">查看更多</span>
      </div>
      <topNews></topNews>
      <div class="new_list">
        <div class="list_item" v-for="item in list" :key="item.newsId" @click="handleDetail(item.newsId,item.type)">
          <div class="justify-between">
            <span class="list_title" :title="item.newsTitle">{{item.newsTitle}}</span>
            <span class="new_time">{{item.newsTime.slice(0,10)}}</span>
          </div>
          <div class="new_text">{{item.newsDesc}}</div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { getNews } from "@/api/news"
import topNews from '@/components/topNews'  //推荐新闻，带图片
export default {
  name: 'newview',
  components: {
    topNews
  },
  data() {
    return {
      queryParams: {  //新闻列表
        maxLimit: 6,
        current: 1,
        type: 1,
      },
      list: [],
    }
  },
  mounted() {
    this.getNews()
  },
  methods: {
    getNews() {
      getNews(this.queryParams).then(res => {
        if (res.code == 0) {
          this.list = res.data.records;

        }
      })
    },
    handleDetail(id, type) {
      let pathInfo = this.$router.resolve({
        path: '/news/' + id,
        query: {
          type: type
        }
      })
      window.open(pathInfo.href, '_blank');
      // this.$router.push({ path: '/news/' + id, query: { type: type } })
    },
    handleMore() {
      this.$router.push({ path: '/news' })
    }
  }
}
</script>
<style scoped lang="scss">
@import url("@/assets/css/common.scss");
.new_box {
  width: 1140px;
  padding-top: 80px;
  margin: auto;
  .new_head {
    margin-bottom: 40px;
  }
  .new_head_text {
    font-size: 36px;
    padding-left: 28px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: bold;
    color: #333333;
    line-height: 48px;
  }
  .new_more {
    position: relative;
    line-height: 48px;
    cursor: pointer;
    padding-right: 19px;
    color: #808080;
    font-size: 14px;
  }
  .new_more::after {
    position: absolute;
    top: 1px;
    content: url("@/assets/images/rightIcon.png");
    width: 13px;
    height: 13px;
  }
  .new_more:hover {
    color: #209701;
  }
  .new_more:hover::after {
    content: url("@/assets/images/newMore.png");
  }
}

.new_list {
  margin-top: 30px;
  margin-bottom: 105px;
  .list_item {
    padding: 30px 0 18px 0;
    float: left;
    width: 530px;
    cursor: pointer;
    border-bottom: 1px solid #e6e6e6;
    .list_title {
      width: 350px;
      font-size: 16px;

      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      color: #333333;
      line-height: 21px;
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
    .new_text {
      width: 350px;
      margin-top: 5px;
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      color: #666666;
      line-height: 22px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
  }
  .list_item:hover .list_title {
    color: #209701;
  }
  .list_item:nth-child(2n + 1) {
    margin-right: 80px;
  }
}
.new_time {
  position: relative;
  padding-left: 24px;
  font-size: 12px;
  font-family: Roboto-Regular, Roboto;
  font-weight: 400;
  color: #808080;
  line-height: 16px;
}
.new_time::after {
  content: url("@/assets/images/timeIcon.png");
  position: absolute;
  left: 0;
  top: 1px;
  width: 13px;
  height: 13px;
}
.new_list::after {
  display: block;
  content: "";
  clear: both;
}
</style>
