/*
 * @Descripttion: 
 * @Author: 张涛
 * @Date: 2022-11-07 14:17:51
 * @LastEditors: 张涛
 * @LastEditTime: 2022-11-28 17:00:42
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
Vue.use(VueRouter)

const routes = [,
  {
    path: '/',
    name: 'home',
    meta: {
      title: '西藏先锋绿能环保科技股份有限公司官网'
    },
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import( /* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta: {
      title: '关于我们 - 先锋绿能 '
    },
  },
  {
    path: '/news',
    name: 'news',
    component: () => import( /* webpackChunkName: "about" */ '../views/pages/NewsList.vue'),
    meta: {
      title: '新闻动态 - 先锋绿能'
    },
  },
  {
    path: '/news/:id',
    name: 'newDetail',
    component: () => import( /* webpackChunkName: "about" */ '../views/pages/NewDetail.vue'),
    meta: {
      title: '新闻详情 - 先锋绿能',
    },
  },
  {
    path: '/business',
    name: 'business',
    component: () => import( /* webpackChunkName: "about" */ '../views/pages/Business.vue'),
    meta: {
      title: '业务领域 - 先锋绿能',
    },
  },
  {
    path: '/business/:id',
    name: 'businessDetail',
    component: () => import( /* webpackChunkName: "about" */ '../views/pages/BusinessDetail.vue'),
    meta: {
      title: '供电服务 - 先锋绿能',
    },
  },
  {
    path: '/joinus',
    name: 'joinus',
    component: () => import( /* webpackChunkName: "about" */ '../views/pages/Join.vue'),
    meta: {
      title: '加入我们 - 先锋绿能'
    },
  }, {
    path: '*',
    name: '404',
    component: () => import( /* webpackChunkName: "about" */ '../views/pages/404.vue'),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    //title处理，首页则直接显示公司名称 ，其他显示 栏目+公司名称
    document.title = to.meta.title
  }
  next()
})

router.afterEach((to, from) => {
  //路由变化时滚动条滚动到顶部
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
})
export default router