<!--
 * @Descripttion: 
 * @Author: 张涛
 * @Date: 2022-11-07 16:48:42
 * @LastEditors: 张涛
 * @LastEditTime: 2022-11-18 17:41:22
-->
<template>
  <div>
    <div class="new_recom justify-between">
      <div class="new_recom_item" v-for="item in data" :key="item.newsId" :style="'background: url('+item.newsImg+')'" @click="handleDetail(item.newsId,item.type)">
        <div class="new_desc">
          <p class="new_title" :title="item.newsTitle" data-aos="fade-up" data-aos-delay="100">{{item.newsTitle}}</p>
          <p class="new_cont" data-aos="fade-up" data-aos-delay="100">{{item.newsDesc}}</p>
          <div class="new_recom_time" data-aos="fade-up" data-aos-delay="100">{{item.newsTime.slice(0,10)}}</div>
        </div>
      </div>

    </div>
  </div>

</template>

<script>

import { getNews } from "@/api/news"
export default {
  name: 'topNews',
  data() {
    return {
      data: [], //推荐新闻
      queryParams: {
        maxLimit: 3,
        current: 1,
        pv: 1
      }
    }
  },
  mounted() {
    getNews(this.queryParams).then(res => {
      if (res.code == 0) {
        this.data = res.data.records
      }
    })
  },
  methods: {
    handleDetail(id, type) {
      let pathInfo = this.$router.resolve({
        path: '/news/' + id,
        query: {
          type: type
        }
      })
      window.open(pathInfo.href, '_blank');
      // this.$router.push({ path: '/news/' + id, query: { type: type } })
    },
  }
}
</script>
<style scoped lang="scss">
@import url("@/assets/css/common.scss");

.new_recom {
  display: flex;

  .new_recom_item {
    position: relative;
    cursor: pointer;
    width: 366px;
    height: 340px;
    background-position: center !important;
    background-size: cover !important;
  }

  .new_desc {
    position: absolute;
    padding: 25px 22px 30px 22px;
    z-index: 9;
    bottom: 0;
    left: 0;
    width: 363px;
    height: 149px;
  }
  .new_title {
    font-size: 16px;
    font-weight: bold;
    width: 100%;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    color: #ffffff;
    line-height: 21px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }

  .new_recom_time {
    position: relative;
    margin-top: 9px;
    padding-left: 18px;
    font-size: 12px;
    font-family: Roboto-Regular, Roboto;
    color: #ffffff;
    line-height: 18px;
  }

  .new_recom_time::after {
    content: url("../assets/images/whiteTime.png");
    position: absolute;
    left: 0;
    top: 2px;
    width: 13px;
    height: 13px;
  }

  .new_cont {
    margin-top: 9px;
    font-size: 12px;
    height: 36px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    color: #ffffff;
    line-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  .new_box {
    position: absolute;
    padding: 46px 42px 0 27px;
    z-index: 99;
    width: 100%;
    height: 100%;
  }
  .new_recom_dec {
    color: #333333;
    margin-top: 10px;
    line-height: 34px;
    font-size: 18px;
  }
  .new_recom_bg {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    width: 100%;
    height: 100%;
    display: none;
    left: 0;
    top: 0;
    z-index: 1;
  }
  .bg_img {
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100%;
    display: none;
  }

  .new_recom_item:hover .new_desc {
    background: rgba(32, 151, 1, 0.44);
  }
}
</style>
