import axios from 'axios'
import {
  Message,
} from 'element-ui';
// import errorCode from '@/utils/errorCode'

// axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({

  baseURL: `https://api.xianfenglvneng.com`,
  // 超时
  timeout: 10000
})


// request拦截器
service.interceptors.request.use(config => {
  if (config.method === 'get') {
    config.data = {
      unused: 0
    } //这个是关键点，加入这行就可以了，解决get请求添加不上Content-Type的问题
  }
  config.headers['Content-Type'] = 'application/json;charset=utf-8'

  // get请求映射params参数
  if (config.method === 'get' && config.params) {
    let url = config.url + '?'
    for (const propName of Object.keys(config.params)) {
      const value = config.params[propName]
      var part = encodeURIComponent(propName) + '='
      if (value && typeof (value) !== 'undefined') {
        if (typeof value === 'object') {
          for (const key of Object.keys(value)) {
            const params = propName + '[' + key + ']'
            var subPart = encodeURIComponent(params) + '='
            url += subPart + encodeURIComponent(value[key]) + '&'
          }
        } else {
          url += part + encodeURIComponent(value) + '&'
        }
      }
    }
    url = url.slice(0, -1)
    config.params = {}
    config.url = url
  }
  return config
}, error => {
  Promise.reject(error)
})

let hasOverdue = false; //是否过期，用于防抖，避免多次提升
// 响应拦截器
service.interceptors.response.use(res => {
    // console.log(res)
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    // 获取错误信息
    // const msg = res.data.message || errorCode[code] || errorCode['default']
    // 二进制数据则直接返回

    //   if (code == 500) {
    //     Message({
    //       message: msg,
    //       type: 'error'
    //     })
    //     return Promise.reject({ code: 500 })
    //   } else if (code == 6000) {
    //     if (!hasOverdue) {
    //       hasOverdue = true;
    //       Message.error('登录状态已过期，请重新登录！')
    //       setTimeout(() => {
    //         window.location.href = `${baseUrl.uapUrl}/login/logout.html?systemCode=IOT_DEVICE`
    //       }, 2000)
    //     }
    //   } else {
    //     return res.data
    //   }
    return res.data
  },
  error => {
    console.log('err' + error)
    let {
      message
    } = error;
    if (message == "Network Error") {
      message = "后端接口连接异常";
    } else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    Message({
      message: message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service