<!--
 * @Descripttion: 
 * @Author: 张涛
 * @Date: 2022-11-07 16:48:42
 * @LastEditors: 张涛
 * @LastEditTime: 2022-11-29 16:38:45
-->
<template>
  <div class="swiper-container swiper1">
    <div class="swiper-wrapper">
      <div class="swiper-slide swiper-slide1">
        <div class="banner_text">
          <div class="banner_title">为油气田勘探开发提供能源保障</div>
          <div class="banner_desc" data-aos="fade-left" data-aos-delay="100">紧跟中央政策，服务国家战略</div>
        </div>
      </div>
      <div class="swiper-slide swiper-slide2">
        <div class="banner_text">
          <div class="banner_title">聚焦能源行业，整合优势资源，以科技创新赋能企业可持续发展
          </div>
          <div class="banner_desc" data-aos="fade-left" data-aos-delay="100">为工业企业和城市终端节能降耗、提质增效</div>
        </div>
      </div>
      <div class="swiper-slide swiper-slide3">
        <div class="banner_text">
          <div class="banner_title">开放与融合、协同与共生</div>
          <div class="banner_desc" data-aos="fade-left" data-aos-delay="100">以客户为中心，永远艰苦奋斗，践行共创共享</div>
        </div>
      </div>
    </div>
    <!-- 如果需要分页器 -->
    <div class="swiper-pagination swiper1-pagination"></div>
    <!-- 如果需要导航按钮 -->
    <!-- <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div> -->
  </div>
</template>

<script>
import Swiper from 'swiper'; // 注意引入的是Swiper
import 'swiper/css/swiper.min.css' // 注意这里的引入

export default {
  name: 'BannerView',
  data() {
    return {
      // swiper实例  实例上有各种方法
      swiperInstance: null
    }
  },
  mounted() {

    this.swiperInstance = new Swiper('.swiper1', {
      loop: true,
      autoplay: {
        delay: 10000,
        disableOnInteraction: false,//用户操作swiper之后，是否禁止autoplay。默认为true：停止。
      },
      roundLengths: true,
      height: 500,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        renderBullet: function (index, className) {
          //使用renderBullet方法，重新初始化pagination结构
          return '<span class="swiper-pagination-bullet"><span class="zt"></span></span>';
        }
      },
    })
    // let pagin = document.querySelector('.swiper1-pagination .swiper-pagination-bullet-active .zt');
    // pagin.style.width = '30px';
  }
}
</script>
<style scoped lang="scss">
.swiper-container {
  width: 100%;
  height: 100%;
}
.swiper-slide {
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-position: center;
}
.swiper-slide1 {
  background-image: url("https://cdn.minexiot.com/xfweb/images/bg1.jpg");
}
.swiper-slide2 {
  background-image: url("https://cdn.minexiot.com/xfweb/images/bg2.png");
}

.swiper-slide3 {
  background-image: url("https://cdn.minexiot.com/xfweb/images/bg3.jpg");
}

::v-deep.swiper-pagination .swiper-pagination-bullet-active {
  // opacity: 1;
  // background: #209701 !important;
  /* 为了保险，这里在使用!important */
}
::v-deep .swiper-pagination .swiper-pagination-bullet-active::after {
  // content: "";
  // position: absolute;
  // left: 0;
  // z-index: 99;
  // height: 4px;
  // width: 30px;
  // background: #209701 !important;
}
::v-deep .swiper-pagination .swiper-pagination-bullet-active .zt {
  position: absolute;
  left: -60px;
  height: 4px;
  width: 60px;
  transform: translateX(0);
  background: #209701;
  animation: fadenum 10s linear both;
}
@keyframes fadenum {
  100% {
    // width: 100%;
    transform: translateX(60px);
  }
}
::v-deep .swiper-pagination .swiper-pagination-bullet {
  width: 60px !important;
  height: 4px !important;
  background: rgba(255, 255, 255, 0.3);
  position: relative;
  opacity: 1;
  border-radius: 0;
  overflow: hidden;
}

.banner_text {
  margin: 328px 0 0 22.5%;
  .banner_title {
    font-size: 44px;
    line-height: 60px;

    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    color: #ffffff;
    letter-spacing: 1px;
    font-weight: bold;
  }
  .banner_desc {
    margin-top: 21px;
    font-size: 28px;

    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    color: #ffffff;
    line-height: 37px;
    letter-spacing: 1px;
  }
}
</style>
