/**
 * 防抖函数
 * @param {}} callback 
 * @param {*} interval 
 * @returns 
 */
export function debounce(callback, interval) {
  let debounceTimeoutId;
  return function (...args) {
    clearTimeout(debounceTimeoutId);
    debounceTimeoutId = setTimeout(() => callback.apply(this, args), interval);
  };
}