/*
 * @Descripttion: 
 * @Author: 张涛
 * @Date: 2022-11-14 15:13:30
 * @LastEditors: 张涛
 * @LastEditTime: 2022-11-14 15:17:46
 */
import request from '@/utils/request'
export function getNews(query) {
    return request({
        url: '/news/list',
        method: 'get',
        params: query
    })
}
export function getNewById(id) {
    return request({
        url: '/news/info',
        method: 'get',
        params: {
            id
        }
    })
}