<!--
 * @Descripttion: 
 * @Author: 张涛
 * @Date: 2022-11-07 15:52:08
 * @LastEditors: 张涛
 * @LastEditTime: 2022-11-28 17:24:29
-->
<template>
  <div class="footer">
    <div class="flex">
      <div class="ul">
        <div class="li logo" data-aos="fade-right" data-aos-duration="1600">
          <a href="/"><img src="../assets/images/logo.png" /></a>
        </div>
        <div class="li ercode" data-aos="fade-left" data-aos-duration="1600">
          <img class="image_1" referrerpolicy="no-referrer" src="../assets/images/ercode.png" />
          <img class="image_1" style="margin-left: 15px;" referrerpolicy="no-referrer" src="../assets/images/ercode2.png" />
        </div>
      </div>
      
      <div class="ul">
        <div class="li" data-aos="fade-up"><a href="/business">业务领域</a></div>
        <div class="li set" data-aos="fade-up" data-aos-delay="300"><a href="/business#eleSupply">清洁替代与供电服务</a></div>
        <div class="li" data-aos="fade-up" data-aos-delay="400"><a href="/business#eleTechnology">电力技术服务</a></div>
        <div class="li" data-aos="fade-up" data-aos-delay="500"><a href="/business#integralEnergy">综合能源服务</a></div>
        <div class="li set" data-aos="fade-up" data-aos-delay="600"><a href="/business#newEnergy">新能源</a></div>
        <div class="li" data-aos="fade-up" data-aos-delay="700"><a href="/business#bigData">能源信息化及大数据</a></div>
        <div class="li" data-aos="fade-up" data-aos-delay="800"><a href="/business#energyTrading">能源交易</a></div>
      </div>
      <div class="ul">
        <div class="li" data-aos="fade-up"><a href="/news">新闻动态</a></div>
        <div class="li" data-aos="fade-up" data-aos-delay="400"><a href="/news#compamy">公司新闻</a></div>
        <div class="li" data-aos="fade-up" data-aos-delay="300"><a href="/news#industry">行业新闻</a></div>
      </div>
      <!-- <div class="ul">
        <div class="li" data-aos="fade-up"><a>服务支持</a></div>
        <div class="li" data-aos="fade-up" data-aos-delay="600"><a>客户服务</a></div>
        <div class="li" data-aos="fade-up" data-aos-delay="700"><a>服务体系</a></div>
        <div class="li" data-aos="fade-up" data-aos-delay="800"><a>项目案列</a></div>
      </div> -->

      <div class="ul">
        <div class="li" data-aos="fade-up"><a href="/about">关于我们</a></div>
        <div class="li" data-aos="fade-up" data-aos-delay="300"><a href="/about#develop">发展历程</a></div>
        <div class="li" data-aos="fade-up" data-aos-delay="400"><a href="/about#party">党建动态</a></div>
        <div class="li" data-aos="fade-up" data-aos-delay="400"><a href="/about#qualifications">企业资质</a></div>
        <div class="li" data-aos="fade-up" data-aos-delay="400"><a href="/about#culture">先锋文化</a></div>
        <div class="li" data-aos="fade-up" data-aos-delay="400"><a href="/about#contact">联系我们</a></div>
      </div>
      <div class="ul">
        <div class="li" data-aos="fade-up"><a href="/joinus">加入我们</a></div>
        <div class="li" data-aos="fade-up" data-aos-delay="300"><a href="/joinus#post">招聘岗位</a></div>
        <div class="li" data-aos="fade-up" data-aos-delay="300"><a href="/joinus#welfare">福利待遇</a></div>
      </div>
    </div>
    <div class="copyright">© Copyright 2022 xianfenglvneng.com All Right Reserved. 蜀ICP备2022026801号</div>
  </div>

</template>

<script>
export default {
  name: 'Footer',
  mounted() { }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
// @import url("../assets/css/common.scss");
@media screen and (max-width: 1440px) {
  /*当屏幕尺寸小于600px时，应用下面的CSS样式*/
  .footer .ul {
    // width: 7.5rem !important;
  }
}

.footer {
  padding-top: 42px;
  width: 100%;
  box-sizing: border-box;
  background: #171a18;

  .flex {
    width: 1200px;
    display: flex;
    margin: 0 auto;
    padding: 0 64px 0px 117px;
    justify-content: center;
    // padding: 0 18vw;

    .ul {
      width: 65px;
      margin-right: 100px;
      &:first-child {
        flex: 1;
        height: 29px;
        text-align: left;
      }
      &:nth-child(2) {
        width: 140px;
      }
      &:last-child {
        margin-right: 0px;
      }

      .li {
        font-size: 14px;
        color: #999999;
        line-height: 36px;
        opacity: 0.88;

        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        &:first-child {
          opacity: 1;
          font-size: 16px;
          line-height: 21px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          margin-bottom: 33px;
          a {
            color: #fff;
          }
        }

        a {
          cursor: pointer;
          color: #999999;
          &:hover {
            text-decoration: underline;
          }
        }
      }

      .ercode {
        pointer-events: none;
      }

      .logo {
        img {
          width: 110px;
          pointer-events: none;
        }
      }

      .ercode {
        text-align: left;
        padding-left: 5px;
        height: 84px;

        img {
          width: 84px;
        }
      }
    }
  }

  .copyright {
    width: 1200px;
    border-top: 1px solid #333333;
    line-height: 100px;
    text-align: center;
    color: #4d4d4d;
    margin: 0 auto;
    margin-top: 60px;
    font-size: 12px;
  }
}
</style>