<!--我们的实力Power-->
<template>
  <div class="power">
    <canvas id="power-light"></canvas>
    <div class="con">
      <div class="datas">
        <div class="items" data-aos="fade-up">
          <div class="label">服务电量</div>
          <div class="n-div">

            <div class="num active" data-aos="fade-left" data-aos-delay="300">
              <MyDigitRoll ref="roll1" :num="14"></MyDigitRoll>
              <!-- <DigitRoll rollDigits="1" :flipStra="true" dur="1000" />
              <DigitRoll rollDigits="1" :flipStra="true" dur="2000" /> -->
            </div>
            <div class="unit">亿度</div>
          </div>
        </div>
        <div class="items" data-aos="fade-up">
          <div class="label">碳减排量</div>
          <div class="n-div">
            <div class="num active" data-aos="fade-left" data-aos-delay="300">
              <!-- <DigitRoll rollDigits="8" :flipStra="true" dur="4000" />
              <DigitRoll rollDigits="6" :flipStra="true" dur="4000" />
              <span>.</span>
              <DigitRoll rollDigits="5" :flipStra="true" dur="4000" /> -->
              <MyDigitRoll ref="roll2" :num="90"></MyDigitRoll>
            </div>
            <div class="unit">万吨</div>
          </div>
        </div>
        <div class="items" data-aos="fade-up">
          <div class="label">能耗节约</div>
          <div class="n-div">
            <div class="num active" data-aos="fade-left" data-aos-delay="300">
              <MyDigitRoll ref="roll3" :num="6.6"></MyDigitRoll>
            </div>
            <div class="unit">亿元</div>
          </div>
        </div>
      </div>
      <div class="datas">
        <div class="items" data-aos="fade-up" data-aos-delay="300">
          <div class="label">柴油替代量</div>
          <div class="n-div">
            <div class="num active" data-aos="fade-left" data-aos-delay="600" data-aos-id="super-duper">
              <MyDigitRoll ref="roll4" :num="27.8"></MyDigitRoll>
            </div>
            <div class="unit">万吨</div>
          </div>
        </div>
        <div class="items" data-aos="fade-up" data-aos-delay="300">
          <div class="label">自建线路长度</div>
          <div class="n-div">
            <div class="num active" data-aos="fade-left" data-aos-delay="600">
              <MyDigitRoll ref="roll5" :num="1385"></MyDigitRoll>
            </div>
            <div class="unit">kM</div>
          </div>
        </div>
        <div class="items" data-aos="fade-up" data-aos-delay="300">
          <div class="label">装机容量</div>
          <div class="n-div">
            <div class="num active" data-aos="fade-left" data-aos-delay="600">
              <MyDigitRoll ref="roll6" :num="916"></MyDigitRoll>
            </div>
            <div class="unit">MVA</div>
          </div>
        </div>
      </div>
      <div class="txt">
        <div data-aos="fade-up" data-aos-delay="300">先锋绿能以电力为纽带，针对各油气田公司油气田开发过程中不同用电阶段和场景，提供专业电力解决方案，通过投资、建设、运营一体化模式为油气开发全生命周期提供用电服务，并全面负责油气田客户智慧综合能源项目实施，逐步探索新能源、储能等领域的业务活动。</div>
        <!-- <div data-aos="fade-up" data-aos-delay="400"></div> -->
      </div>
    </div>
  </div>
</template>

<script>

import { drawLightning } from "@/utils/drawLightning"
import { debounce } from "@/utils/index"
//数字滚动
import MyDigitRoll from './MyDigitRoll.vue';
export default {
  name: 'Power',
  components: {
    MyDigitRoll,
  },
  data() {
    return {
      digits: 1256,
    }
  },
  mounted() {
    document.addEventListener('aos:in:super-duper', debounce(this.initDraw.bind(this), 100));
  },
  methods: {
    initDraw({ detail }) {
      if (detail.getAttribute('data-aos-id')) {
        this.startRoll();
        drawLightning(document.getElementById('power-light'))
      }
    },
    startRoll() {
      Array.from({ length: 6 }).forEach((_, index) => {
        this.$refs['roll' + (index + 1)].start()
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@media screen and (max-width: 1440px) {
  /*当屏幕尺寸小于600px时，应用下面的CSS样式*/
  // .power .datas:first-child {
  //   padding-top: 13% !important;
  // }
  // .power .txt {
  //   padding-bottom: 20% !important;
  // }
}
.power {
  position: relative;
  #power-light {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1;
  }
  background-image: url(https://cdn.minexiot.com/xfweb/images/index-pw.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  .con {
    background: rgba(1, 15, 12, 0.3);
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    // justify-content: space-around;
    align-items: center;
  }
  .datas {
    // flex: 1;
    color: #fff;
    display: flex;
    width: 51vw;
    justify-content: space-between;
    // padding: 5% 5%;

    &:first-child {
      padding-top: 21vh;
      padding-bottom: 0;
    }
    &:nth-child(2) {
      padding-top: 10.5vh;
      padding-bottom: 12.9vh;
    }
  }
  .txt {
    text-align: center;
    width: 51vw;
    line-height: 32px;
    overflow-wrap: break-word;
    color: rgba(255, 255, 255, 1);
    font-size: 16px;
    text-align: left;
    padding-bottom: 30.7vh;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  }

  ::v-deep .d-roll-wrapper {
    margin: 0;
    padding: 0px 5px;
    color: #209701;
    width: auto;
  }
  ::v-deep .d-roll-item > .d-roll-bar > div {
    line-height: 1;
  }
  .items {
    width: 250px;
    .label {
      font-size: 18px;
      letter-spacing: 2px;
      line-height: 2.5vh;
      font-weight: 400;
      margin-bottom: 1.6vh;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    }
    .n-div {
      justify-content: left;
      display: flex;
      height: 50px;
    }
    .num {
      font-family: osp-din, sans-serif;
      color: #209701;
      // width: 100px;
      font-size: 55px;
      font-weight: bold;
      margin-right: 20px;
      // padding: 0px 5px;
      font-weight: bold;
      font-family: "din";
      display: flex;
    }
    .unit {
      padding-top: 1.8vh;
      line-height: 3.7vh;
      margin-right: 11px;
      letter-spacing: 1px;
      font-size: 18px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    }
  }
}
</style>
