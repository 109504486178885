<!--
 * @Descripttion: 
 * @Author: 张涛
 * @Date: 2022-11-07 15:52:08
 * @LastEditors: 张涛
 * @LastEditTime: 2022-11-29 15:24:31
-->
<template>
  <div class="navs" ref="navs">
    <div class="nav_box">
      <div class="logo_box">
        <div class="logo"></div>
      </div>
      <ul class="nav_ul">
        <li>
          <router-link :class="name=='首页'?'nav_active':''" to="/">首页</router-link>
        </li>
        <li>
          <router-link :class="name=='业务领域'?'nav_active':''" to="/business">业务领域</router-link>
        </li>
        <li>
          <router-link :class="name=='新闻动态'?'nav_active':''" to="/news">新闻动态</router-link>
        </li>
        <li>
          <router-link :class="name=='关于我们'?'nav_active':''" to="/about">关于我们</router-link>
        </li>
        <li>
          <router-link :class="name=='加入我们'?'nav_active':''" to="/joinus">加入我们</router-link>
        </li>
        <li>
          <div class="lingo_box">
            <div class="lsc_warp" @click="show=!show" title="中、英切换">
              <span class="lcs_cursor" :class="show?'':'lsc_cursor_off'">{{show?'中文':'英文'}}</span>
              <span class="lsc_on">{{show?'中文':'切换'}}</span>
              <span class="lsc_off">{{show?'切换':'英文'}}</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavsView',
  data() {
    return {
      show: true,
      name: null,
    }
  },

  mounted() {
    this.name = this.$route.meta.title;

  },
  methods: {

  },

}
</script>

<style scoped lang="scss">
.navs {
  width: 100%;
  height: 70px;
  position: fixed;
  transition: all 0.5s ease-in-out;
  z-index: 99;
  top: 0;
}
.header-fixed {
  background: #fff;
  border-bottom: 1px #eee solid;
  .nav_ul li a {
    color: #333333;
  }
  .logo {
    width: 100%;
    height: 34px;
    background: url("@/assets/images/logo2.png") !important;
    background-position: center !important;
    background-size: cover !important;
  }
  .nav_active {
    font-weight: bold !important;
    color: #333333 !important;
  }
  .nav_active::after {
    width: 100% !important;
  }
  .lsc_warp {
    border: 1px solid #ddd;
    // background: #209701;
  }
  .lcs_cursor {
    background: #209701;
    color: #fff;
  }
  .lsc_on,
  .lsc_off {
    color: #333333;
  }
}

.nav_box {
  width: 1200px;
  margin: auto;
  display: flex;
  .logo_box {
    width: 114px;
    padding-top: 18px;
    .logo {
      width: 100%;
      height: 34px;
      background: url("@/assets/images/logo.png");
      background-position: center;
      background-size: cover;
    }
  }
}
.nav_ul {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}
.nav_ul li {
  list-style: none;
  margin-left: 77px;
  min-width: 67px;
  a {
    position: relative;
    line-height: 70px;
    display: block;
    padding: 0 10px;
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #ffffff;
    text-decoration: none;
  }
  .lingo_box {
    height: 70px;
    padding-top: 25px;
  }
}

.nav_ul li a::after {
  content: "";
  height: 3px;
  background: #209701;
  position: absolute;
  left: 0;
  width: 0;
  top: 67px;
  transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
}
.nav_ul li a:hover:after {
  width: 100%;
}

.munes {
  height: 0px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}
.nav_ul li:hover .munes {
  overflow: none;
  height: auto;
}
.lsc_warp {
  cursor: pointer;
  position: relative;
  width: 84px;
  height: 20px;
  border-radius: 13px;
  // background: #209701;
  border: 1px solid #ffffff;
}
.lcs_cursor {
  display: inline-block;
  position: absolute;
  font-size: 12px;
  font-weight: 400;
  right: 40px;
  width: 42px;
  height: 18px;
  text-align: center;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  border-radius: 18px;
  color: #209701;
  background: #fff;
  transition: all 0.3s;
}
.lsc_on,
.lsc_off {
  width: 50%;
  vertical-align: top;
  display: inline-block;
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-family: sourceHanSansCN-Regula, SourceHanSansCN;
  font-weight: 400;
}
.lsc_cursor_off {
  right: -1px !important;
}
</style>
