<!--滚动数字封装，已支持小数点的情况-->
<template>
  <div class="roll-num">
    <template v-for="item in nums">
      <span v-if="item.point">.</span>
      <DigitRoll v-else :rollDigits="item.n" :dur="item.dur" />
    </template>
  </div>
</template>
<script>
//数字滚动
import DigitRoll from '@huoyu/vue-digitroll';
export default {
  name: 'MyDigitRoll',
  props: {
    num: { //滚动数字
      type: Number,
      default: 0,
    },
    dur: {
      type: Number,
      default: 2000,
    }, //定义第一个数字的动画时间
    k: {
      type: Number,
      default: 1000,
    }, //每个数字的间隔时间
  },
  components: {
    DigitRoll,

  },
  data() {
    return {
      nums: [],
    }
  },
  mounted() {

  },
  methods: {
    start() {
      let arr = [];
      let num = this.num + '';
      for (let i = 0; i < num.length; i++) {
        const item = num[i];
        arr.push({
          n: item === '.' ? '.' : item * 1,
          dur: this.dur + i * this.k,
          point: item === '.',
        })
      }
      this.nums = arr;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.roll-num {
  display: flex;
}

::v-deep .d-roll-wrapper {
  margin: 0;
  padding: 0px 5px;
  // color: #209701;
  width: auto;
}

::v-deep .d-roll-item > .d-roll-bar > div {
  line-height: 1;
}
</style>