/*
 * @Descripttion: 
 * @Author: 张涛
 * @Date: 2022-11-09 09:35:02
 * @LastEditors: 张涛
 * @LastEditTime: 2022-11-16 09:23:21
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import VueMeta from 'vue-meta'

import {
  Pagination
} from 'element-ui'; //按需加载Pagination分页组件
import 'element-ui/lib/theme-chalk/index.css';
// import './utils/rem'
//animate动画样式
import 'aos/dist/aos.css';


Vue.config.productionTip = false
Vue.use(Pagination);
//meta
// Vue.use(VueMeta)
new Vue({
  router,
  store,
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  },
  render: h => h(App)
}).$mount('#app')