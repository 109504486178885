/**
 * 绘制闪电
 * @param {dom} canvas 
 */
export function drawLightning(canvas) {
  let width, height;
  canvas.width = width = window.innerWidth
  canvas.height = height = window.innerHeight
  let show = true //显示隐藏
  let top = canvas.parentNode.offsetTop;
  let maxTop = height + top;
  let minTop = top;

  var step = 0;
  var ctx = canvas.getContext('2d')
  ctx.fillStyle = 'rgba(255, 255, 255, 0)';

  document.onmousedown = function (e) {
    pt2.x = e.pageX;
    pt2.y = e.pageY - top;
  }
  document.onmousemove = function (e) {
    pt1.x = e.pageX;
    pt1.y = e.pageY - top;
    if (e.pageY > maxTop || e.pageY < minTop) {
      show = false;
    } else {
      show = true;
    }
  }
  var pt1 = {
    x: width * 0.2,
    y: height * 0.3
  }
  var pt2 = {
    x: width * 0.8,
    y: height * 0.7
  }

  window.addEventListener('resize', setup)


  setup()

  function setup() {
    canvas.width = width = window.innerWidth
    canvas.height = height = window.innerHeight

    top = canvas.parentNode.offsetTop;
    maxTop = height + top;
    minTop = top;

    pt1 = {
      x: width * 0.2,
      y: height * 0.2
    }
    pt2 = {
      x: width * 0.8,
      y: height * 0.8
    }
    // draw()
  }

  setInterval(animate, 50)
  // window.requestAnimationFrame(animate);

  function blur(ctx, canvas, amt) {
    ctx.filter = `blur(${amt}px)`
    ctx.drawImage(canvas, 0, 0)
    ctx.filter = 'none'
  }

  function fade(ctx, amt, width, height) {
    // ctx.beginPath();
    // ctx.rect(0, 0, width, height)
    // ctx.fillStyle = `rgba(255, 255, 255, 0)`
    // ctx.fill()
    ctx.clearRect(0, 0, width, height)
  }

  function animate() {
    step++
    // blur(ctx, canvas, 1)

    fade(ctx, 0.17, width, height)
    draw()
    // window.requestAnimationFrame(function () {
    //   animate()
    // })
  }

  function draw() {

    var iterations = [pt1, pt2]
    var newiterations, i, j
    for (i = 0; i < 8; i++) {
      newiterations = [iterations[0]]
      for (j = 1; j < iterations.length; j++) {
        newiterations.push(getRandMidpoint(iterations[j - 1], iterations[j], 200 / (i * i + 1)))
        newiterations.push(iterations[j])
      }
      iterations = newiterations.concat([])
    }
    ctx.beginPath();
    ctx.moveTo(iterations[0].x, iterations[0].y);
    ctx.lineWidth = 1;
    ctx.strokeStyle = '#d4e4fb';
    ctx.strokeStyle = `hsla(${Math.sin( step / 30) * 120 + 50},${90}%,${70}%,${show?0.1:0})`
    for (i = 1; i < iterations.length; i++) {
      ctx.lineTo(iterations[i].x, iterations[i].y);
    }
    ctx.stroke()
    ctx.closePath()
  }

  function getRandMidpoint(pa, pb, range) {
    var a = Math.atan2(pb.y - pa.y, pb.x - pa.x) + Math.PI / 2
    var half = {
      y: (pb.y - pa.y) / 2 + pa.y,
      x: (pb.x - pa.x) / 2 + pa.x
    }
    var offset = Math.random() * range - range / 2
    var ho = {
      x: Math.cos(a) * offset + half.x,
      y: Math.sin(a) * offset + half.y
    }
    return ho
  }
}